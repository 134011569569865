// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./i9sDfZ91V-0.js";

const cycleOrder = ["e4yToWGgk"];

const variantClassNames = {e4yToWGgk: "framer-v-jm8t5q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, B8w6vYBo_: title ?? props.B8w6vYBo_ ?? "Pozrite si moje práce"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, B8w6vYBo_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "e4yToWGgk", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2WqZh", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-jm8t5q", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"e4yToWGgk"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center"}}>Pozrite si moje práce</motion.p></React.Fragment>} className={"framer-1d48t32"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"Zi9vVjjzG"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={B8w6vYBo_} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2WqZh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2WqZh .framer-1ef2jqx { display: block; }", ".framer-2WqZh .framer-jm8t5q { height: 50px; overflow: hidden; position: relative; width: 198px; }", ".framer-2WqZh .framer-1d48t32 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 198
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"B8w6vYBo_":"title"}
 * @framerImmutableVariables false
 */
const Frameri9sDfZ91V: React.ComponentType<Props> = withCSS(Component, css, "framer-2WqZh") as typeof Component;
export default Frameri9sDfZ91V;

Frameri9sDfZ91V.displayName = "cdd";

Frameri9sDfZ91V.defaultProps = {height: 50, width: 198};

addPropertyControls(Frameri9sDfZ91V, {B8w6vYBo_: {defaultValue: "Pozrite si moje práce", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Frameri9sDfZ91V, [])